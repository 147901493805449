<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="销售订单详情" name="first">
          <div>
            <el-row :gutter="30">
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">订单号：</span>
                <span class="text">{{saledeailsList.s_code}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">客户名称：</span>
                <span class="text">{{saledeailsList.c_name}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">联系人：</span>
                <span class="text">{{saledeailsList.cc_name}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">时间：</span>
                <span class="text">{{saledeailsList.s_date}}</span>
              </el-col>

            </el-row>
            <!--   用户列表区域   -->
            <el-table :data="saledeailsList.goodslist"  border stripe>
              <el-table-column type="index" ></el-table-column>
              <el-table-column label="商品编码" prop="ga_code" width="80px"></el-table-column>
              <el-table-column label="商品名称" prop="g_name" ></el-table-column>
              <el-table-column label="商品规格" prop="ga_name" >
                <template slot-scope="scope" >
                  <div>{{scope.row.ga_name}}</div>
                  <div v-if="scope.row.ga_start_date">{{scope.row.ga_start_date}}{{scope.row.ga_end_date}}</div>

                </template>
              </el-table-column>
              <el-table-column label="单位" prop="gu_name" width="50px"></el-table-column>
              <el-table-column label="最终销售价"  prop="si_price"></el-table-column>
<!--              <el-table-column label="开始时间"  prop="gad_start_date"></el-table-column>-->
<!--              <el-table-column label="结束时间"  prop="gad_end_date"></el-table-column>-->
              <el-table-column label="数量" width="90px" prop="si_number"></el-table-column>
              <el-table-column label="销售总价" prop="si_total_price"></el-table-column>
            </el-table>
          </div>
          <el-button style="float: right;margin-top: 30px;" type="primary" v-print="'#print'" v-if="this.$route.query.is_printing=='true'">打印</el-button>

        </el-tab-pane>
        <el-tab-pane label="销售订单付款记录" name="center">
          <div>
            <el-row :gutter="30">
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">订单号：</span>
                <span class="text">{{saledeailsList.s_code}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">客户名称：</span>
                <span class="text">{{saledeailsList.c_name}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">联系人：</span>
                <span class="text">{{saledeailsList.cc_name}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">时间：</span>
                <span class="text">{{saledeailsList.s_date}}</span>
              </el-col>
            </el-row>
            <el-table :data="saledeailsList.salepayment"  stripe  >
              <el-table-column type="index"></el-table-column>
              <el-table-column label="支付流水号" prop="p_serial_number"></el-table-column>
              <el-table-column label="付款日期" prop="p_payment_date"></el-table-column>
              <el-table-column label="付款金额" >
                <template slot-scope="scope">
                  <div style="color:red;font-weight: bold;">
                    {{scope.row.p_payment_price}}</div>
                </template>
              </el-table-column>
              <el-table-column label="支付方式">
                <template slot-scope="scope">
                  <div style="padding: 2px 5px;background: #8c939d;display: inline-block;
            color:#ffffff;border-radius: 4px">
                    {{scope.row.p_payment_method}}</div>
                </template>
              </el-table-column>
              <el-table-column label="付款账户" prop="p_bank_account"></el-table-column>
            </el-table>
          </div>
          <el-button style="float: right;margin-top: 30px;" type="primary" v-print="'#printText'" v-if="this.$route.query.is_printing=='true'">打印</el-button>

        </el-tab-pane>
        <el-tab-pane label="销售出库详情" name="foot">
          <div>
            <div v-for="(item,index) in saledeailsList.warehouseout" v-bind:key="index" style="margin-bottom: 50px">
              <el-row :gutter="30">
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                  <span class="title">订单号：</span>
                  <span class="text">{{item.wo_code}}</span>
                </el-col>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">仓库：</span>
                  <span class="text">{{item.w_name}}({{item.wp_name}})</span>
                </el-col>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">经办人：</span>
                  <span class="text">{{item.wo_agent_name}}</span>
                </el-col>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">日期：</span>
                  <span class="text">{{item.wo_date}}</span>
                </el-col>
              </el-row>

              <el-table :data="item.goodslist" border stripe  >
                <el-table-column type="index"></el-table-column>
                <el-table-column label="商品编码" prop="ga_code"></el-table-column>
                <el-table-column label="商品名称" prop="g_name"></el-table-column>
                <el-table-column label="商品规格" prop="ga_name">
                  <template slot-scope="scope" >
                    <div>{{scope.row.ga_name}}</div>
                    <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}{{scope.row.gad_end_date}}</div>

                  </template>
                </el-table-column>
<!--                <el-table-column label="开始时间" prop="gad_start_date"></el-table-column>-->
<!--                <el-table-column label="结束时间" prop="gad_end_date"></el-table-column>-->
                <el-table-column label="单位" prop="gu_name"></el-table-column>
                <el-table-column label="已入库数量" prop="wei_number"></el-table-column>
              </el-table>

              <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
                <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >-->

                <!--        </el-col>-->
              </el-row>
              <el-row>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">备注：</span>
                  <span class="text">{{saledeailsList.wo_remark}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">制单人：</span>
                  <span class="text">{{saledeailsList.add_name}}</span>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-button style="float: right;margin-top: 30px;" type="primary" v-print="'#printTest'" v-if="this.$route.query.is_printing=='true'">打印</el-button>

        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!--  销售订单详情打印  -->
    <div>
      <div style="opacity: 0;height: 0;">
        <div style="width: 1000px;margin:0 auto;"  id="print">
          <el-row :gutter="30">
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">订单号：</span>
              <span class="text">{{saledeailsList.s_code}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">客户名称：</span>
              <span class="text">{{saledeailsList.c_name}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">联系人：</span>
              <span class="text">{{saledeailsList.cc_name}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">时间：</span>
              <span class="text">{{saledeailsList.s_date}}</span>
            </el-col>

          </el-row>
          <!--   用户列表区域   -->
          <el-table :data="saledeailsList.goodslist"  border stripe>
            <el-table-column type="index" ></el-table-column>
            <el-table-column label="商品编码" prop="ga_code" width="80px"></el-table-column>
            <el-table-column label="商品名称" prop="g_name" ></el-table-column>
            <el-table-column label="商品规格" prop="ga_name" >
              <template slot-scope="scope" >
                <div>{{scope.row.ga_name}}</div>
                <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}{{scope.row.gad_end_date}}</div>
              </template>
            </el-table-column>
            <el-table-column label="单位" prop="gu_name" width="50px"></el-table-column>
            <el-table-column label="最终销售价"  prop="si_price"></el-table-column>
<!--            <el-table-column label="开始时间"  prop="gad_start_date"></el-table-column>-->
<!--            <el-table-column label="结束时间"  prop="gad_end_date"></el-table-column>-->
            <el-table-column label="数量" width="90px" prop="si_number"></el-table-column>
            <el-table-column label="销售总价" prop="si_total_price"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--  销售订单付款记录打印  -->
    <div>
      <div style="opacity: 0;height: 0;">
        <div style="width: 1000px;margin:0 auto;"  id="printText">
          <el-row :gutter="30">
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">订单号：</span>
              <span class="text">{{saledeailsList.s_code}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">客户名称：</span>
              <span class="text">{{saledeailsList.c_name}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">联系人：</span>
              <span class="text">{{saledeailsList.cc_name}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">时间：</span>
              <span class="text">{{saledeailsList.s_date}}</span>
            </el-col>
          </el-row>
          <el-table :data="saledeailsList.salepayment"  stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="支付流水号" prop="sp_serial_number"></el-table-column>
            <el-table-column label="付款日期" prop="sp_payment_date"></el-table-column>
            <el-table-column label="付款金额" prop="sp_payment_price"></el-table-column>
            <el-table-column label="支付方式" prop="sp_payment_method"></el-table-column>
            <el-table-column label="付款账户" prop="sp_bank_account"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--  销售出库详情打印  -->
    <div>
      <div style="opacity: 0;height: 0;">
        <div style="width: 1000px;margin:0 auto;"  id="printTest">
          <div v-for="(item,index) in saledeailsList.warehouseout" v-bind:key="index" style="margin-bottom: 50px">
            <el-row :gutter="30">
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">订单号：</span>
                <span class="text">{{item.wo_code}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">仓库：</span>
                <span class="text">{{item.w_name}}({{item.wp_name}})</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">经办人：</span>
                <span class="text">{{item.wo_agent_name}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">日期：</span>
                <span class="text">{{item.wo_date}}</span>
              </el-col>
            </el-row>

            <el-table :data="item.goodslist" border stripe  >
              <el-table-column type="index"></el-table-column>
              <el-table-column label="商品编码" prop="ga_code"></el-table-column>
              <el-table-column label="商品名称" prop="g_name"></el-table-column>
              <el-table-column label="商品规格" prop="ga_name">
                <template slot-scope="scope" >
                  <div>{{scope.row.ga_name}}</div>
                  <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}{{scope.row.gad_end_date}}</div>
                </template>
              </el-table-column>
<!--              <el-table-column label="开始时间" prop="gad_start_date"></el-table-column>-->
<!--              <el-table-column label="结束时间" prop="gad_end_date"></el-table-column>-->
              <el-table-column label="单位" prop="gu_name"></el-table-column>
              <el-table-column label="已入库数量" prop="wei_number"></el-table-column>
            </el-table>

            <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
              <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >-->

              <!--        </el-col>-->
            </el-row>
            <el-row>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">备注：</span>
                <span class="text">{{saledeailsList.wo_remark}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">制单人：</span>
                <span class="text">{{saledeailsList.add_name}}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import XLSX from 'xlsx'

export default {
  data() {
    return{
      saledeailsList:{},
      activeName: 'first',
    }
  },
  created() {
    this.getsaledeailsList()
  },
  methods: {
    //获取商品分类
    async getsaledeailsList() {
      const {data: res} = await this.$http.get(`sale/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.saledeailsList = res.data
    },
    //tab切换
    handleClick(tab, event) {
      console.log(tab, event);
    }
  },
}
</script>
<style lang="less" scoped>
.el-cascader{
  width: 300px;
}
.el-select{
  width: 250px;
}
.p_remark{
  width: 250px;
}
.el-input__inner{
  width: 250px;
}
</style>
